<template>
  <v-card flat class="rounded-lg mt-4">
    <v-card-title>
      <span class="text-color-title body-1 font-weight-bold">
        {{ title }}
      </span>
      <v-spacer></v-spacer>
      <v-btn small depressed dark color="black" @click="deleteComponent()">
        Delete
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-textarea
          v-model="question"
          class="px-4 rounded-lg"
          placeholder="Add Question"
          :rules="MIXINS_REQUIRED_RULES"
          dense
          filled
          rows="5"
          auto-grow
        ></v-textarea>
        <div class="my-2">
          <div
            v-for="(option, key) in options"
            :key="key"
            class="d-flex align-center px-4"
          >
            <v-text-field
              placeholder="Add Option"
              v-model="option.text"
              :rules="MIXINS_REQUIRED_RULES"
              dense
            ></v-text-field>
            <v-btn
              small
              text
              color="default"
              class="ml-4"
              @click="removeOption(key)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="d-flex align-center px-4">
            <v-spacer></v-spacer>
            <v-btn small depressed dark color="cyan" @click="addOption()">
              Add option
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { BUILDER_COMPONENT_EVENT_BUS } from "../../services/builderComponentEventBus";

export default {
  data: () => ({
    title: "Poll",
    question: null,
    options: [],
    valid: false
  }),
  props: {
    form: {
      type: Object,
      default: () => {
        return {};
      }
    },
    itemId: {
      type: Number,
      default: null
    }
  },
  methods: {
    deleteComponent() {
      let key = this.$attrs.componentKey;

      BUILDER_COMPONENT_EVENT_BUS.$emit("deleteComponent", {
        key,
        partId: this.itemId
      });
    },
    validate() {
      return this.$refs.form.validate();
    },
    addOption() {
      this.options.push({
        text: null
      });
    },
    removeOption(key) {
      this.options.splice(key, 1);
    }
  },
  mounted() {
    this.question = this.form.question || null;
    this.options = this.form.options || [];
  }
};
</script>
